import * as React from "react";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import PageTitle from "../components/PageTitle";
import BookingForm from "../components/BookingForm";

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <PageTitle title={'Make A Booking'}/>

    <BookingForm/>
  </Layout>
)

export default Contact;